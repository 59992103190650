import { graphql, Link, useStaticQuery, navigate } from 'gatsby';
import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { CustomNavbarToggle, StyledHeader, StyledNavbar } from './styledHeader';
import LanguagePicker from '../../langauagePicker';
import { Container, Nav, Navbar } from 'react-bootstrap';
import DonateButton from '../../DonateButton/DonateButton';
import RedirectButton from "../../RedirectButton/RedirectButton";
import Img from "gatsby-image";

const Header = ({ language, pathEn, pathRo }: any) => {
  const data = useStaticQuery(graphql`
    query PageQuery {
      drupal {
        en: menuByName(name: "main", language: EN) {
          links {
            url {
              path
            }
            label
            description
            expanded
            links {
              url {
                path
              }
              label
            }
          }
        }
        ro: menuByName(name: "main", language: RO) {
          links {
            url {
              path
            }
            label
            description
            expanded
            links {
              url {
                path
              }
              label
            }
          }
        }
      }
    }
  `);

  const { links } = data.drupal[language] || {};
  const menuLinks = links.filter((elem: any) => {
    return elem.description !== 'is_donate_link' && elem.description !== 'is_redirect_link';
  });
  const donateLink = links.filter((elem: any) => {
    return elem.description === 'is_donate_link';
  });
  const redirectLink = links.filter((elem: any) => {
    return elem.description === 'is_redirect_link';
  });

  return (
    <StyledHeader>
      <StyledNavbar bg='white' expand='md'>
        <div className='header-content__donate-banner'>
          <>
            <StaticImage
              style={{
                marginRight: '5px',
              }}
              src='../../../images/svg/heart.svg'
              alt='Heart'
            />
            <div className='d-inline-flex justify-content-between'>
              {/*{donateLink.length !== 0 && (
                <Link to={donateLink[0]?.url.path} style={{ color: 'white', textDecoration: 'none' }}>
                  {donateLink[0]?.label}
                </Link>
              )}*/}

              {redirectLink.length !== 0 && (
                <a href={redirectLink[0]?.url.path} style={{ paddingLeft:'10px', color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                  {redirectLink[0]?.label}
                </a>
              )}
            </div>
          </>
        </div>
        <Container>
          <Navbar.Brand href='/'>
            <span>
              <StaticImage
                className='header-content__logo'
                src='../../../images/logo-primary.jpg'
                alt='ASSIST Foundation logo'
                layout='constrained'
                width={158}
                height={54}
              />
            </span>
          </Navbar.Brand>
          <div className='d-inline-flex align-items-center'>
            <div className='header-content__flag header-content__flag--mobile justify-content-end'>
              <LanguagePicker language={language} pathEn={pathEn} pathRo={pathRo} />
            </div>

            <CustomNavbarToggle>
              <StaticImage
                className='header-content__hamburger'
                width={24}
                height={24}
                src='../../../images/svg/navbar-hamburger.svg'
                alt='Hamburger'
              />
            </CustomNavbarToggle>
          </div>
          <Navbar.Collapse>
            <Nav className='flex-grow-1 justify-content-between'>
              <div className='header-content__left-panel'>
                {(menuLinks || []).map((link: any, index: number) => (
                  <Nav.Item key={`${index}menuLinks`} className='header-content__main-menu nav-link position-relative'>
                    <div className='d-flex'>
                      <Link className='header-content__link' to={link.url.path} activeClassName='header-content__link--active'>
                        {link.label}
                      </Link>

                      {(link.expanded) && (
                          <span className='caret ps-2 pt-2'>
                            <StaticImage className='dropdown__caret' src='../../images/svg/dropdownCaret.svg' alt='Caret' />
                          </span>
                      )}
                    </div>

                    <div className='header-content__sub-menu'>
                      {(link.links || []).map((subLink: any, subIndex: number) => (
                          <Nav.Item key={`${subIndex}menuLinks`} className='nav-link'>
                            <Link className='header-content__link' to={subLink.url.path} activeClassName='header-content__link--active'>
                              {subLink.label}
                            </Link>
                          </Nav.Item>
                      ))}
                    </div>
                  </Nav.Item>
                ))}
              </div>
              <div className='flex-grow-1 justify-content-end navbar-nav'>
                <div className='header-content__flag header-content__flag--desktop'>
                  <LanguagePicker language={language} pathEn={pathEn} pathRo={pathRo} />
                </div>
                <div className='header-content__donate justify-content-between navbar-nav'>
                    <DonateButton onClick={() => navigate(donateLink[0]?.url.path)}>{donateLink[0]?.label}</DonateButton>
                    {redirectLink.length !== 0 && (
                      <RedirectButton onClick={() => window.open(redirectLink[0]?.url.path)}>{redirectLink[0]?.label}</RedirectButton>
                    )}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
    </StyledHeader>
  );
};

export default Header;
